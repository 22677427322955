<template lang="pug">
v-container
    v-row
      v-col(cols="12")
        h3(class="text-none mt-4" :style="{color: textColorHex}") {{ form.formName }}
          v-divider(:color="textColorHex" class="mt-2 mb-2") 
          h6(class="text-none font-weight-light" :style="{color: textColorHex}") {{ form.formDescription }}
</template>

<script>
import bg1Svg from '../templates/sprinkle.vue';
import bg2Svg from '../templates/simple-shiny.vue';
import bg3Svg from '../templates/meteor.vue';
import bg4Svg from '../templates/animated-shape.vue';
import bg5Svg from '../templates/wave.vue';

import { mapGetters } from "vuex";
export default {
  name: "Cover",
  props: {
    id: {
      type: String,
      required: true
    },
  },
  components: {
    bg1Svg,
    bg2Svg,
    bg3Svg,
    bg4Svg,
    bg5Svg
  },
  data() {
    return {
      id2: '', 
      primaryColor: '#154360', // Default color
      bg: 'bg1',
      text: 'center',
      text1: 'center',
      slide: 'cover',
      selectedSvg: bg1Svg, // Default SVG bg
    };
  },
  computed: {
    ...mapGetters('form',['getSlide']),
    getColorVariants() {
      return this.getLighterAndDarkerShades(this.primaryColor) || [];
    },
    form() {
      return this.$store.state.form;
    },
    textColorHex() {
      return this.$store.state.textColorHex;
    },
  },

  methods: {
    changeSvg(bgName) {
      switch (bgName) {
        case 'bg1':
          this.selectedSvg = bg1Svg;
          break;
        case 'bg2':
          this.selectedSvg = bg2Svg;
          break;
        case 'bg3':
          this.selectedSvg = bg3Svg;
          break;
        case 'bg4':
          this.selectedSvg = bg4Svg;
          break;
        case 'bg5':
          this.selectedSvg = bg5Svg;
          break;
        default:
          this.selectedSvg = null;
          break;
      }
    },
    changeColor(newColor) {
      this.primaryColor = newColor;
    },
    nextSlide() {
      this.$router.push("/2");
    },
    submit() {
      alert("submit");
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },

    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },

    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },

    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 30),
        this.adjustColor(g, 30),
        this.adjustColor(b, 30)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      return [lighterShade, darkerShade];
    }
  }
};
</script>

<style scoped>
body {
  width: 100%;
  margin: 0;
}
html {
  height: auto;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}
.title {
    display: flex;
    text-align: right;
    border-radius: 8px;
}
.upload-area {
    height: 400px;
    width: 100%;
    /* display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 8px; */
  }
</style>
